import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";

const PieChart = ({ total, walletHolders }) => {
  const [series, setSeries] = useState([44, 55, 41]);
  const [options, setOptions] = useState({});

  console.log("total => ", total);

  useEffect(() => {
    if (walletHolders) {
      setSeries([
        0,
        Number(walletHolders?.ethUsers - 4),
        Number(walletHolders?.polygonUsers),
      ]);
    }
  }, [walletHolders]);

  useEffect(() => {
    if (walletHolders) {
      setOptions({
        labels: ["Matic", "Ethereum", "Binance"],
        legend: {
          show: false,
        },

        tooltip: {
          enabled: true,
          style: {
            fontSize: "12px",
          },

          fillSeriesColor: false,
          marker: {
            show: false,
          },
        },

        stroke: {
          show: false,
          curve: "smooth",
          lineCap: "butt",
          colors: undefined,
          width: 2,
          dashArray: 0,
        },
        chart: {
          type: "donut",
          height: 500,
          width: 30,
        },
        fill: {
          colors: ["#8247E5", "#aa5e81", "#b4b3b5"],
          style: {
            borderRadius: 100,
            background: "#fff",
          },
        },
        dataLabels: {
          enabled: false,
          enabledOnSeries: undefined,

          textAnchor: "middle",
          distributed: false,
          offsetX: 0,
          offsetY: 0,
          style: {
            fontSize: "10px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: "bold",
            colors: undefined,
          },
          background: {
            enabled: true,
            foreColor: "#fff",
            padding: 4,
            borderRadius: 2,
            borderWidth: 1,
            borderColor: "#fff",
            opacity: 0.9,
            dropShadow: {
              enabled: false,
              top: 1,
              left: 1,
              blur: 1,
              color: "#000",
              opacity: 0.45,
            },
          },
          dropShadow: {
            enabled: false,
            top: 1,
            left: 1,
            blur: 1,
            color: "#000",
            opacity: 0.45,
          },
        },

        plotOptions: {
          pie: {
            startAngle: 0,
            endAngle: 360,
            expandOnClick: false,
            offsetX: 0,
            offsetY: 50,
            customScale: 1.2,
            dataLabels: {
              offset: 0,
              minAngleToShowLabel: 10,
            },
            donut: {
              size: "80%",
              background: "transparent",
              labels: {
                show: true,

                name: {
                  show: true,
                  fontSize: "16px",
                  fontFamily: "Poppins",
                  fontWeight: 600,
                  color: "#fff",
                  offsetY: 25,
                  formatter: function (val) {
                    return val;
                  },
                },
                value: {
                  show: true,
                  fontSize: "34px",
                  fontFamily: "Poppins",
                  fontWeight: 400,
                  color: "#fff",
                  offsetY: -20,
                  formatter: function (val) {
                    return "value";
                  },
                },
                total: {
                  show: true,
                  showAlways: true,
                  label: "Total Users",
                  fontSize: "16px",
                  fontFamily: "Poppins",

                  fontWeight: 400,
                  color: "#fff",
                  // formatter: function (w) {
                  //   if (w.globals.seriesTotals) {
                  //     return w.globals.seriesTotals.reduce((a, b) => {
                  //       return a + b;
                  //     }, 0);
                  //   } else {
                  //     return "-";
                  //   }
                  // },
                },
              },
            },
          },
        },
      });
    }
  }, [total]);

  return <Chart options={options} series={series} type="donut" />;
};

export default PieChart;
