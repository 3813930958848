import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  btn: {
    backgroundColor: "transparent",
    border: "transparent",
    width: "155px",
    height: "40px",
    color: theme.palette.textColor,
    borderRadius: "50px",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 600,
    cursor: "pointer",
    "@media(max-width:550px)": {
      width: "130px",
    },

    "@media(max-width:450px)": {
      width: "120px",
    },
    "@media(max-width:400px)": {
      width: "105px",
    },
  },
  selected: {
    background: theme.palette.themeGradient1,
    boxShadow: theme.palette.themeBoxShadow1,
    borderRadius: "40px",
  },
}));
const AppContainerButtons = ({ label, selected, changeStakeFunc }) => {
  const classes = useStyles();

  return (
    <div>
      <button
        onClick={() => changeStakeFunc(label)}
        className={[
          classes.btn,
          selected == label ? classes.selected : "",
        ].join(" ")}
      >
        {label}
      </button>
    </div>
  );
};

export default AppContainerButtons;
