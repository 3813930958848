import { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import AppContainerButtons from "./AppContainerButtons";
import Stake from "./Stake";
import Unstake from "./Unstake";
import Withdraw from "./Withdraw";
import Header from "../components/Header";
import { APP_CARD_BORDER, APP_STAKE_PAGE_TITLE } from "../utils/constants";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // height: "61px",
    marginBottom: "25px",
    flexDirection: "column",
    marginTop: "45px",
    marginTop: "30px",
  },

  nav: {
    background: theme.palette.cardBgColor,

    border: theme.palette.border,
    borderRadius: "40px",
    display: "flex",
    padding: "5px",
    marginBottom: "25px",
    "@media(max-width:550px)": {
      marginRight: "20px",
      width: "95%",
      justifyContent: "center",
    },
  },
}));
const AppContainer = () => {
  const classes = useStyles();

  const [selected, setSelected] = useState("Stake");
  const changeStakeFunc = (label) => {
    setSelected(label);
  };
  const showComponent = (selected) => {
    if (selected == "Stake") {
      return <Stake />;
    } else if (selected == "Unstake") {
      return <Unstake />;
    } else if (selected == "Withdraw") {
      return <Withdraw />;
    }
  };

  useEffect(() => {
    document.querySelector("title").textContent = APP_STAKE_PAGE_TITLE;
  }, []);
  return (
    <div>
      <div className={classes.container}>
        <div className={classes.nav}>
          <AppContainerButtons
            label={"Stake"}
            selected={selected}
            changeStakeFunc={changeStakeFunc}
          />

          <AppContainerButtons
            label={"Unstake"}
            selected={selected}
            changeStakeFunc={changeStakeFunc}
          />

          <AppContainerButtons
            label={"Withdraw"}
            selected={selected}
            changeStakeFunc={changeStakeFunc}
          />
        </div>

        {showComponent(selected)}
      </div>
    </div>
  );
};

export default AppContainer;
