import { Box, makeStyles, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import MarketGraph from "./MarketGraph";
import TimeRangeButton from "./TimeRangeButton";
import { useDispatch, useSelector } from "react-redux";

import { getCoinMarketChart } from "../../redux/actions/web3Actions";
import moment from "moment";
import { APP_TOKEN_NAME } from "../../utils/constants";
import Text from "../Misc/Text";

const MarketGraphCard = () => {
  const classes = useStyles();

  const [currentTimeFrame, setCurrentTimeFrame] = useState("4h");

  const timeFrames = ["4h", "1d", "1w", "1m"];

  const dispatch = useDispatch();

  const coinCurrentPrice = useSelector(
    (state) => state.web3.data.coinCurrentPrice
  );

  const onTimeFrameClick = (timeFrame) => {
    setCurrentTimeFrame(timeFrame);
    if (timeFrame == "4h") {
      dispatch(getCoinMarketChart(moment().subtract(4, "hours").unix()));
    } else if (timeFrame == "1d") {
      dispatch(getCoinMarketChart(moment().subtract(1, "day").unix()));
    } else if (timeFrame == "1w") {
      dispatch(getCoinMarketChart(moment().subtract(1, "week").unix()));
    } else if (timeFrame == "1m") {
      dispatch(getCoinMarketChart(moment().subtract(1, "month").unix()));
    }
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.header}>
        <Typography className={classes.heading}>
          {APP_TOKEN_NAME.toUpperCase()}/USD: ${coinCurrentPrice}
        </Typography>

        <Box className={classes.timeRangeContainer}>
          <Typography className={classes.timeHeading}>Time Range:</Typography>

          <Box>
            {timeFrames.map((timeFrame) => (
              <TimeRangeButton
                selected={timeFrame === currentTimeFrame}
                onClick={() => onTimeFrameClick(timeFrame)}
                text={timeFrame}
              />
            ))}
          </Box>
        </Box>
      </Box>

      <MarketGraph />
    </Box>
  );
};

export default MarketGraphCard;

const useStyles = makeStyles((theme) => ({
  comingSoonContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",

    [theme.breakpoints.down("960")]: {
      height: 300,
    },
  },
  container: {
    // minWidth: 770,
    height: "100%",
    background: theme.palette.cardBgColor,
    border: theme.palette.border,
    borderRadius: "25px",
    padding: 24,
    paddingBottom: 0,
    marginRight: 16,

    [theme.breakpoints.down("sm")]: {
      padding: 16,
      paddingBottom: 0,
      marginRight: 0,
      marginBottom: 16,
    },
  },

  header: {
    display: "flex",
    justifyContent: "space-between",

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },

  heading: {
    fontWeight: 500,
    fontSize: "24px",
    lineHeight: "20px",
    color: theme.palette.textColor,

    [theme.breakpoints.down("sm")]: {
      marginBottom: 16,
    },
  },

  timeHeading: {
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "12px",
    color: theme.palette.textColor,

    marginRight: 8,
  },

  timeRangeContainer: {
    display: "flex",
    alignItems: "center",

    [theme.breakpoints.down("sm")]: {
      marginBottom: 16,
      justifyContent: "space-between",
    },
  },
}));
