import React, { useEffect, useState } from "react";
import { CardContent, makeStyles, Typography, List } from "@material-ui/core";
import CustomCard from "../Cards/CustomCard";
import { TopUser1, TopUser2, TopUser3, TopUser4, TopUser5 } from "../../assets";
import TopUserRow from "./TopUserRow";
import { useSelector } from "react-redux";

let avatarArray = [TopUser5, TopUser4, TopUser3, TopUser2, TopUser1];

const TopUsersCard = () => {
  const classes = useStyles();

  const { topFiveUsers } = useSelector((state) => state.web3.data);

  const [topUsers, setTopUsers] = useState([]);

  console.log("topFiveUsers => ", topFiveUsers);

  const gradients = [
    "linear-gradient(180deg, #11CDF6 0%, #2C34F3 100%)",
    "linear-gradient(180deg, #4DF611 0%, #2CF3E7 100%)",
    "linear-gradient(180deg, #1151F6 0%, #BF2CF3 100%)",
    "linear-gradient(180deg, #F61F11 0%, #B32CF3 100%)",
    "linear-gradient(180deg, #F66511 0%, #F3B22C 100%)",
  ];

  useEffect(() => {
    setTopUsers([]);
    topFiveUsers.map((user, index) => {
      setTopUsers((arr) => [
        ...arr,
        {
          address: user.id,
          amount: user.amount,
          gradient: gradients[index],

          icon: avatarArray[index],
        },
      ]);
    });
  }, [topFiveUsers]);

  return (
    <CustomCard height={430}>
      <CardContent className={classes.cardContent}>
        <Typography className={classes.text}>Top 5 Users</Typography>

        <List style={{ padding: 0 }}>
          {topUsers.map((user, index) => (
            <TopUserRow
              address={user.address}
              amount={user.amount}
              gradient={user.gradient}
              icon={user.icon}
              showBorder={topUsers.length !== index + 1}
            />
          ))}
        </List>
      </CardContent>
    </CustomCard>
  );
};

export default TopUsersCard;

const useStyles = makeStyles((theme) => ({
  text: {
    color: theme.palette.textColor,
    fontSize: "18px",
    lineHeight: "24px",
    marginBottom: 30,
  },

  cardContent: {
    display: "flex",
    flexDirection: "column",
    padding: 0,
  },
}));
