import { makeStyles } from "@material-ui/core";
import { useWeb3React } from "@web3-react/core";
import { RewardPolygen } from "../../assets";
import { AMOUNT_FEE_RATIO, APP_TOKEN_NAME } from "../../utils/constants";

const InfoImg = ({ rewards }) => {
  const classes = useStyles();
  const context = useWeb3React();
  return (
    <div className={classes.container}>
      <div className={classes.imgContainer}>
        <img src={RewardPolygen} className={classes.img} />
        <small>You will get up to</small>
        <div className={[classes.dafiAmount, classes.rewardColor].join(" ")}>
          {context?.chainId===137 ? Number(rewards) ? Number(rewards * AMOUNT_FEE_RATIO)?.toFixed(4) : 0 : 0}
        </div>
        <span className={classes.textSize_20}>
          {APP_TOKEN_NAME.toUpperCase()}
        </span>
      </div>
    </div>
  );
};

export default InfoImg;

const useStyles = makeStyles((theme) => ({
  container: { width: "100%" },
  dafiAmount: {
    fontSize: "28px",
    fontWeight: 700,
    margin: "5px 0px",
  },

  rewardColor: {
    background: theme.palette.textGradient1,
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
  },
  img: { marginBottom: "20px", height: 60 },
  imgContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: theme.palette.cardBgColor,
    border: theme.palette.border,
    color: theme.palette.textColor,

    backdropFilter: "blur(25px)",
    paddingTop: "25px",
    paddingBottom: "30px",
    marginBottom: "20px",
    borderRadius: "25px",

    "@media(max-width:550px)": {
      paddingTop: "16px",
      paddingBottom: "16px",
    },
    textSize_20: {
      fontSize: "20px",
    },
  },
}));
