import { Box, makeStyles, Typography } from "@material-ui/core";

import React from "react";
import { sixDigitsFormatter, trunc } from "../../utils/formatters";

import { useSelector } from "react-redux";

const TVLCard = ({ card }) => {
  const coinCurrentPrice = useSelector(
    (state) => state.web3.data.coinCurrentPrice
  );

  const currentNetwork = useSelector((state) => state.web3.currentNetwork);
  const classes = useStyles({ currentNetwork });

  return (
    <Box elevation={1} className={classes.card}>
      <Box className={classes.cardContent}>
        <Box className={classes.sectionContainer}>
          <Typography className={classes.sectionValue}>
            {card.dafiLocked && coinCurrentPrice
              ? `$${sixDigitsFormatter(card.dafiLocked * coinCurrentPrice)}`
              : "Coming Soon"}
          </Typography>
          <Typography className={classes.sectionTitle}>TVL Value</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default TVLCard;

const useStyles = makeStyles((theme) => ({
  card: {
    background: theme.palette.cardBgColor,
    border: theme.palette.border,
    borderRadius: 20,

    display: "flex",

    boxShadow: "none",
    height: 200,

    marginBottom: 16,
    position: "relative",
    cursor: "pointer",
  },

  text: {
    color: theme.palette.textColor,

    fontSize: "18px",
    lineHeight: "24px",
    textAlign: "center",
  },

  value: {
    fontWeight: "bold",
    fontSize: "28px",
    lineHeight: "20px",
    color: theme.palette.textColor,

    marginBottom: 20,
  },

  cardContent: {
    display: "flex",

    padding: 24,

    // justifyContent: "space-between",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },

  image: {
    height: 60,
    marginBottom: 24,
  },

  title: {
    fontWeight: 500,
    fontSize: 16,
    lineHeight: "14px",
    color: theme.palette.textColor,
  },

  sectionTitle: {
    fontWeight: 500,
    fontSize: 12,
    lineHeight: "14px",
    color: theme.palette.textColor,

    textAlign: "center",
  },

  sectionValue: {
    fontWeight: 600,
    fontSize: 24,
    lineHeight: "14px",

    marginBottom: 30,
    color: ({ currentNetwork }) =>
      currentNetwork === 2 ? "#68788E" : "#a37ce6",
  },

  divider: {
    width: 2,
    background: "#DFE3EA",
  },

  sectionContainer: {
    padding: "0 50px",
  },

  overlay: {
    position: "absolute",

    backdropFilter: "blur(6px)",
    height: "100%",
    width: "100%",
  },
}));
