import React, { useEffect, useState } from "react";
import { Box, CardContent, makeStyles } from "@material-ui/core";

import Chart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";

import FilterButton from "../Buttons/FilterButton";
import { getGraphData } from "../../redux";
import { useWeb3React } from "@web3-react/core";

import Text from "../Misc/Text";
import CustomCard from "../Cards/CustomCard";
import { calculateSpecificDaysToTimestamp } from "../../utils/formatters";

const GraphCard = () => {
  const classes = useStyles();

  const graphData = useSelector((state) => state.web3.data.graphData);
  const rewards = useSelector((state) => state.web3.data.rewards);

  const [filters, setFilters] = useState([true, false, false, false]);
  const [filter, setFilter] = useState("day");

  const web3Context = useWeb3React();

  const currentNetwork = useSelector((state) => state.web3.currentNetwork);

  const [sevenDaysGraph, setSevenDaysGraph] = useState(false);

  const [thirtyDaysGraph, setThirtyDaysGraph] = useState(false);

  useEffect(() => {
    setFilter("day");
    setFilters([true, false, false, false]);
  }, [web3Context.account]);

  const stakingPeriodValue = useSelector(
    (state) => state.web3.data.stakingPeriodValue
  );

  useEffect(() => {
    if (Number(stakingPeriodValue) > 0) {
      setSevenDaysGraph(
        calculateSpecificDaysToTimestamp(stakingPeriodValue, 7)
      );

      setThirtyDaysGraph(
        calculateSpecificDaysToTimestamp(stakingPeriodValue, 30)
      );
    }
  }, [stakingPeriodValue]);

  useEffect(() => {
    if (graphData?.rewardBalanceArray.length > 0) {
      let newRewardArray = [...graphData?.rewardBalanceArray];
      let newTimeArray = [...graphData?.timeArray.map((item) => Number(item))];

      if (rewards >= 0) {
        newRewardArray.push(rewards.toFixed(5));
        newTimeArray.push(Date.now());
      }

      console.log("newRewardArray => ", newRewardArray);
      setSeries([
        {
          name: "Reward",
          data: newRewardArray,
        },
      ]);

      console.log("newTimeArray => ", newTimeArray);
      setOptions({
        ...options,
        colors: ["#F65C0D"],
        xaxis: {
          type: "datetime",
          categories: newTimeArray,
          show: graphData.timeArray.length > 0,
        },

        yaxis: {
          show: graphData.rewardBalanceArray.length > 0,
          decimalsInFloat: 5,
        },
        grid: {
          show: graphData.rewardBalanceArray.length > 0,
        },
      });
    } else {
      setSeries([
        {
          name: "Reward",
          data: [],
        },
      ]);

      setOptions({
        ...options,
        colors: ["#F65C0D"],
        xaxis: {
          type: "datetime",
          categories: [],
          show: false,
        },

        yaxis: {
          show: false,
          decimalsInFloat: 0,
        },
        grid: {
          show: false,
        },
      });
    }
  }, [graphData, rewards]);

  const [series, setSeries] = useState([]);

  const dispatch = useDispatch();

  const [options, setOptions] = useState({
    chart: {
      height: 350,
      width: 30,
      type: "area",
      zoom: {
        enabled: false,
        type: "x",
        autoScaleYaxis: false,
        zoomedArea: {
          fill: {
            color: "#90CAF9",
            opacity: 0.4,
          },
          stroke: {
            color: "#0D47A1",
            opacity: 0.4,
            width: 1,
          },
        },
      },
      toolbar: {
        show: false,
      },
      foreColor: "#000",
      fontFamily: "Poppins",
    },

    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      type: "datetime",
      categories: [],
    },
    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm",
      },
    },

    legend: {
      show: false,
    },

    yaxis: {
      show: false,
    },

    // xaxis: {
    //   show: false,
    // },

    grid: {
      show: false,
    },

    noData: {
      text: "No Data Available",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: undefined,
        fontSize: "20px",
        fontFamily: undefined,
      },
    },
  });

  useEffect(() => {
    if (filter && web3Context?.account && currentNetwork) {
      dispatch(
        getGraphData(
          web3Context.account,
          web3Context?.chainId,
          currentNetwork,
          filter
        )
      );
    }
  }, [filter, currentNetwork]);

  return (
    <CustomCard customStyles={classes.card}>
      <CardContent className={classes.cardContent}>
        <Box
          justifyContent="space-between"
          flexDirection="row"
          display="flex"
          alignItems="center"
          className={classes.header}
        >
          <Text
            text="Reward Balance"
            customStyles={classes.text}
            fontSize={18}
            lineHeight={24}
          />
          <Box>
            <FilterButton
              text="1 Day"
              active={filters[0]}
              onClick={() => {
                setFilter("day");

                setFilters([true, false, false, false]);
              }}
            />
            <FilterButton
              text="7 Days"
              active={filters[1]}
              onClick={() => {
                if (sevenDaysGraph) {
                  setFilter("week");
                  setFilters([false, true, false, false]);
                }
              }}
            />
            <FilterButton
              text="30 Days"
              active={filters[2]}
              onClick={() => {
                if (thirtyDaysGraph) {
                  setFilter("month");
                  setFilters([false, false, true, false]);
                }
              }}
            />
            <FilterButton
              text="All"
              active={filters[3]}
              onClick={() => {
                if (thirtyDaysGraph) {
                  setFilter("allTime");
                  setFilters([false, false, false, true]);
                }
              }}
            />
          </Box>
        </Box>

        <Chart options={options} series={series} type="area" height={350} />
      </CardContent>
    </CustomCard>
  );
};

export default GraphCard;

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: 16,
    marginRight: 16,

    [theme.breakpoints.down("sm")]: {
      padding: 16,
      paddingBottom: 0,
      height: 500,
      marginRight: 0,
    },
  },

  text: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: 12,
    },
  },

  cardContent: {
    display: "flex",
    flexDirection: "column",
    padding: 0,
  },

  header: {
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      marginBottom: 12,
      alignItems: "flex-start",
    },
  },
}));
