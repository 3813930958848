import {
  Box,
  Card,
  CardContent,
  CardHeader,
  makeStyles,
  Typography,
  Divider,
  Tooltip,
  IconButton,
  Zoom,
} from "@material-ui/core";

import React from "react";
import { LockSvg } from "../../assets";
import { sixDigitsFormatter, trunc } from "../../utils/formatters";
import { Image, InfoOutlined } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  card: {
    background: (props) =>
      props.active
        ? "linear-gradient(139.63deg, #605384 -23.88%, #0F0824 100%)"
        : "linear-gradient(139.63deg, rgba(96, 83, 132, 0.6) -23.88%, rgba(15, 8, 36, 0.6) 100%)",
    border: (props) =>
      props.active ? "2px solid #fff" : "2px solid transparent",
    borderRadius: 20,

    display: "flex",
    // justifyContent: "center",
    // alignItems: "center",
    // flexDirection: "column",
    boxShadow: "none",
    height: 200,

    // width: "auto",

    // [theme.breakpoints.up("sm")]: {
    //   maxWidth: 350,
    // },

    // margin: "auto",
    // justifyContent: "center",
    marginRight: 16,
    marginBottom: 16,
    position: "relative",
    cursor: "pointer",
  },

  text: {
    color: "rgba(255,255,255,.8)",
    fontSize: "18px",
    lineHeight: "24px",
    textAlign: "center",
  },

  value: {
    fontWeight: "bold",
    fontSize: "28px",
    lineHeight: "20px",
    color: "#fff",
    marginBottom: 20,
  },

  cardContent: {
    display: "flex",

    padding: 24,

    flexDirection: "column",
    justifyContent: "space-between",
  },

  image: {
    width: 36,

    height: 36,
    marginRight: 10,
  },

  title: {
    fontWeight: 500,
    fontSize: 16,
    lineHeight: "14px",
    color: "#fff",
  },

  sectionTitle: {
    fontWeight: 500,
    fontSize: 12,
    lineHeight: "14px",
    color: "#fff",
  },

  sectionValue: {
    fontWeight: 600,
    fontSize: 16,
    lineHeight: "14px",
    color: (props) => props.card.themeColor,
    marginBottom: 30,
  },

  divider: {
    width: 2,
    background: "rgba(255, 255, 255, 0.1)",
  },

  sectionContainer: {
    padding: "0 12px",
  },

  overlay: {
    position: "absolute",

    backdropFilter: "blur(6px)",
    height: "100%",
    width: "100%",
  },
}));

const SuperPoolCard = ({ card, active, onClick }) => {
  const classes = useStyles({ card, active });

  console.log("SuperPoolCard => ", card);

  return (
    <Box elevation={1} className={classes.card} onClick={onClick}>
      {/* {card.blur && <div className={classes.overlay}></div>} */}
      <Box className={classes.cardContent}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <img className={classes.image} src={card.icon} />

            <Typography className={classes.title}>{card.title}</Typography>
          </Box>

          {card.blur && <img src={LockSvg} />}
        </Box>

        <Box display="flex" justify="space-between">
          <Box className={classes.sectionContainer}>
            <Typography className={classes.sectionValue}>
              {card.blur
                ? "--"
                : card.dafiLocked
                ? sixDigitsFormatter(card.dafiLocked)
                : "0"}
            </Typography>
            <Typography className={classes.sectionTitle}>
              DAFI Locked
            </Typography>
          </Box>

          <Divider
            orientation="vertical"
            flexItem
            className={classes.divider}
          />

          <Box className={classes.sectionContainer}>
            <Typography className={classes.sectionValue}>
              {card.blur ? "--" : `${Number(card.APY) ? card.APY : "0"}%`}
            </Typography>
            <Box display="flex" alignItems="center">
              <Typography className={classes.sectionTitle}>APY</Typography>
              <Tooltip
                title={
                  "Your dDAFI rewards can self-multiply at peak demand and become the 'Potential APY' displayed."
                }
                arrow
                TransitionComponent={Zoom}
              >
                <IconButton
                  aria-label="delete"
                  style={{ padding: 0, marginLeft: 5 }}
                >
                  <InfoOutlined style={{ color: "#fff", fontSize: 16 }} />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>

          <Divider
            orientation="vertical"
            flexItem
            className={classes.divider}
          />

          <Box className={classes.sectionContainer}>
            <Typography className={classes.sectionValue}>
              {card.blur
                ? "--"
                : `${card.potentialAPY ? trunc(card.potentialAPY) : "0"}%`}
            </Typography>
            <Typography className={classes.sectionTitle}>
              Potential APY
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SuperPoolCard;
