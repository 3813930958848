import { createTheme } from "@material-ui/core";
import { APP_CARD_BG_COLOR, APP_FONT_NAME } from "./utils/constants";

export const theme = createTheme({
  typography: {
    fontFamily: APP_FONT_NAME,
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontWeightMedium: 600,
    fontWeightBold: 700,
    color: "red",
  },

  palette: {
    cardBgColor: "#fff",
    snackbarColor: "#E5E5E5",

    themeColor1: "#ffffff",
    themeColor1RGBA: "18, 27, 45",

    themeGradient1: "#0DFFBC",
    themeBoxShadow1:
      "inset 0px -48.3961px 40.1333px -37.7725px rgba(255, 255, 255, 0.12)",
    border: "2px solid #DFE3EA",

    textGradient1: "linear-gradient(89.91deg, #122D2A 28.96%, #FA053B 124.15%)",

    textColor: "#1F1262",
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: "none",
      },
    },
  },
});
