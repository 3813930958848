import {
  Backdrop,
  CircularProgress,
  makeStyles,
  Box,
  Typography,
  Button,
} from "@material-ui/core";
import { useWeb3React } from "@web3-react/core";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import {
  initializeWeb3,
  sendTransaction,
} from "../contract/functions/functions";

import ResponsiveDrawer from "./Misc/ResponsiveDrawer";

import { useDispatch, useSelector } from "react-redux";

import Header from "./Header";

import * as web3Actions from "../redux";
import FullPageLoader from "../assets/dashboard/app-loader.gif";
import DafiLoader from '../assets/nitro/DafiLoader.gif';
import ConnectWallet from "./ConnectWallet";
import bgImage from '../assets/Background.png';

import { isMobile } from "react-device-detect";

const DRAWER_WIDTH = 250;

const Layout = ({ children }) => {
  const classes = useStyles();

  const location = useLocation();

  const web3Context = useWeb3React();

  const appLoading = useSelector((state) => state.web3.data.appLoading);

  const firstAppLoading = useSelector(
    (state) => state.web3.data.firstAppLoading
  );

  const networkSet = useSelector((state) => state.web3.networkSet);

  const currentNetwork = useSelector((state) => state.web3.currentNetwork);

  const isTrustWallet = useSelector((state) => state.web3.isTrustWallet);

  const [showLaunchScreen, setShowLaunchScreen] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      let res = await window?.ethereum?._metamask?.isUnlocked();

      console.log("Window ethereum response => ", {
        isUnlocked: res,
        active: web3Context.active,
        accounts: window?.ethereum,
      });
    })();
  }, [web3Context.active]);

  useEffect(() => {
    if (web3Context.chainId) {
      if (web3Context.chainId === 1 || web3Context.chainId === 4) {
        dispatch(web3Actions.setCurrentNetwork(2));
      } else if (web3Context.chainId === 137) {
        dispatch(web3Actions.setCurrentNetwork(3));
      } else if (web3Context.chainId === 80001) {
        dispatch(web3Actions.setCurrentNetwork(4));
      }
    }
  }, [web3Context.chainId]);

  useEffect(() => {
    console.log("starting....", {
      web3Context,
      currentNetwork,
    });

    if (currentNetwork && web3Context.account) {
      initializeWeb3(
        web3Context.chainId,
        web3Context?.library?.currentProvider,
        currentNetwork,
        isTrustWallet,
        () => {
          dispatch({
            type: "SET_NETWORK",
            payload: true,
          });
        }
      );
    }
  }, [web3Context, currentNetwork, networkSet]);

  useEffect(() => {
    dispatch(
      web3Actions.getTransactionsHistory(web3Context?.chainId, currentNetwork)
    );
    dispatch(web3Actions.getTopFiveUsers(web3Context?.chainId, currentNetwork));
    dispatch(
      web3Actions.getTotalNetworkStakes(web3Context?.chainId, currentNetwork)
    );

    dispatch(web3Actions.getGasEstimationPrice());

    setTimeout(() => {
      dispatch(web3Actions.setFirstAppLoading(false));
    }, 3000);

    if (isMobile) {
      setTimeout(() => {
        dispatch(web3Actions.setFirstAppLoading(false));
      }, 3000);
    }

    if (web3Context.account && web3Context.active && networkSet) {
      console.log("getChainData => ", currentNetwork);
      dispatch(
        web3Actions.getChainData(
          web3Context.account,
          currentNetwork,
          web3Context?.chainId
        )
      );

      dispatch(web3Actions.getTotalFeesDeposited(currentNetwork));
      dispatch(web3Actions.getTotalDafiStaked());
      dispatch(web3Actions.getCoinDemandFactor(currentNetwork));
      dispatch(
        web3Actions.getOverallAPY(web3Context?.chainId, web3Context.account)
      );
      setTimeout(() => {
        dispatch(web3Actions.setFirstAppLoading(false));
      }, 3000);
    }
  }, [web3Context, networkSet, currentNetwork]);

  console.log("isTrustWallet=> ", isTrustWallet);
  useEffect(() => {
    if (currentNetwork) {
      dispatch(
        web3Actions.getGraphData(
          web3Context.account,
          web3Context?.chainId,
          currentNetwork
        )
      );
    }
  }, [currentNetwork, web3Context]);
  return (
    <div className={classes.root}  >
      {/* style={{backgroundImage: `url(${bgImage})`}} */}
      {/* <Backdrop open={appLoading} className={classes.backdrop}>
        <CircularProgress color="inherit" />
      </Backdrop> */}

      <ConnectWallet />

      {firstAppLoading ? (
        <div className="full-page-loader">
          <img src={DafiLoader} className="full-page-loader-image" />
          {/* <video className="full-page-loader-image" autoPlay loop>
            <source src={DafiVideoLoader} type="video/mp4" />
          </video> */}
        </div>
      ) : !showLaunchScreen ? (
        <div className="launch-screen">
          <Box className={classes.textContainer}>
            <Typography className={classes.heading}>
              Enter into the Staking 2.0 universe and enjoy new rewards.
            </Typography>
            <Button
              className={classes.btnText}
              onClick={() => setShowLaunchScreen(false)}
            >
              Super Stake now!
            </Button>
          </Box>
        </div>
      ) : (
        <>
          <ResponsiveDrawer />
          <div className={classes.page}>
            <Header />
            <div className={classes.toolbar} style={{ height: "100%" }}>
              {children}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Layout;

const useStyles = makeStyles((theme) => {
  return {
    page: {
      [theme.breakpoints.up("lg")]: {
        padding: 20,
        marginTop: 0,
        padding: "20px",
      },
      width: "100%",
      padding: "0 16px",
      // background: "#0F0824",
      marginTop: "100px",

      maxWidth: "1400px",
      margin: "0 auto",
      // height: "100vh",
    },
    root: {
      display: "flex",
      // height: '100%',
      overflow: "hidden",
      // backgroundImage: `url(${bgImage})`,
      // backgroundSize: 'cover'
    },

    drawer: {
      width: DRAWER_WIDTH,
      // background: "#0F0824",
      background: "transparent",
    },

    drawerPaper: {
      width: DRAWER_WIDTH,
      // background: "rgba(15, 8, 36, 1)",
      background: "transparent",
      opacity: 0.75,
      border: "1px solid rgba(230, 231, 233, 0.1)",
    },
    toolbar: theme.mixins.toolbar,

    title: {
      padding: theme.spacing(2),
      color: "#fff",
    },

    listItemText: {
      color: "rgba(255,255,255,.7)",
      fontSize: 22,
      lineHeight: "27px",
    },

    listItemTextActive: {
      color: "rgba(255,255,255)",
      fontSize: 22,
      lineHeight: "27px",
    },

    active: {
      color: "#fff",
    },

    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },

    textContainer: {
      width: "700px",
      marginLeft: 200,
      marginTop: 100,

      [theme.breakpoints.down("1000")]: {
        marginLeft: 100,
        marginTop: 100,
      },

      [theme.breakpoints.down("800")]: {
        marginLeft: 30,
        marginTop: 100,
      },

      [theme.breakpoints.down("600")]: {
        width: "500px",
      },

      [theme.breakpoints.down("450")]: {
        width: "400px",
      },

      [theme.breakpoints.down("400")]: {
        marginLeft: 10,
        width: "340px",
      },
    },

    heading: {
      fontSize: "58px",
      lineHeight: "67px",
      fontWeight: 700,
      color: "#fff",
      fontFamily: "Roboto",
      marginBottom: 20,

      [theme.breakpoints.down("800")]: {
        fontSize: "48px",
        lineHeight: "57px",
      },

      [theme.breakpoints.down("600")]: {
        fontSize: "48px",
        lineHeight: "57px",
      },

      [theme.breakpoints.down("550")]: {
        fontSize: "42px",
        lineHeight: "57px",
      },

      [theme.breakpoints.down("500")]: {
        fontSize: "35px",
        lineHeight: "57px",
      },

      [theme.breakpoints.down("450")]: {
        fontSize: "30px",
        lineHeight: "47px",
      },
    },

    btnText: {
      backgroundColor: "#fff",
      width: 200,

      fontSize: "16px",
      lineHeight: "31px",
      fontWeight: 600,

      "&:hover": {
        backgroundColor: "rgba(255,255,255,.8)",
      },
    },
  };
});
