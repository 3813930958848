import {
  Box,
  Button,
  Grid,
  Link,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import { MarketComingSoon } from "../assets";
import Header from "../components/Header";
import MarketGraphCard from "../components/MarketComponents/MarketGraphCard";
import MarketGraph from "../components/MarketComponents/MarketGraphCard";
import WalletHoldersCard from "../components/MarketComponents/WalletHoldersCard";
import TokenStatsCard from "../components/MarketComponents/TokenStatsCard";
import ExchangesCard from "../components/MarketComponents/ExchangesCard";
import TwitterFeedCard from "../components/MarketComponents/TwitterFeedCard";
import { Twitter } from "@material-ui/icons";
import {
  getTotalWalletHoldersCount,
  getCoinMarketChart,
  getCoinCurrentPrice,
  getCoinOtherData,
  getCoinDemandFactor,
  getTwitterTweets,
} from "../redux/actions/web3Actions";
import moment from "moment";

const Market = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const networkSet = useSelector((state) => state.web3.networkSet);
  useEffect(() => {
    dispatch(getTotalWalletHoldersCount());
    dispatch(getCoinMarketChart(moment().subtract(4, "hours").unix()));
    dispatch(getCoinCurrentPrice());
    dispatch(getCoinOtherData());

    dispatch(getTwitterTweets());

    if (networkSet) dispatch(getCoinDemandFactor());
  }, [networkSet]);

  useEffect(() => {
    document.querySelector("title").textContent = "DAFI Market";
  }, []);

  return (
    <>
      <Grid container className={classes.cardsContainer}>
        <Grid item xs={12} md={7} lg={8}>
          <MarketGraphCard />
        </Grid>
        <Grid item xs={12} md={5} lg={4}>
          <TokenStatsCard />
        </Grid>
      </Grid>

      <Grid container className={classes.exchangeCardTablet}>
        <Grid item xs={12} md={12} lg={12}>
          <ExchangesCard />
        </Grid>
      </Grid>

      <Grid container className={classes.walletHolderFeedContainer}>
        <Grid item xs={12} md={5} lg={4}>
          <WalletHoldersCard />
        </Grid>
        <Grid item xs={12} md={7} lg={8}>
          <Box display="flex" flexDirection="column" height="100%">
            <Box className={classes.exchangeCardNonTablet}>
              <ExchangesCard />
            </Box>
            <TwitterFeedCard />
          </Box>
        </Grid>
      </Grid>
      <Grid container className={classes.walletHolderFeedContainerTablet}>
        <Grid item xs={5} md={5} lg={4}>
          <WalletHoldersCard />
        </Grid>
        <Grid item xs={7} md={7} lg={8}>
          <Box display="flex" flexDirection="column" height="100%">
            <Box className={classes.exchangeCardNonTablet}>
              <ExchangesCard />
            </Box>
            <TwitterFeedCard />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Market;

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100%",
    paddingRight: 16,
  },
  card: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },

  logo: {
    marginBottom: 30,

    [theme.breakpoints.down("415")]: {
      height: 300,
    },
  },

  title: {
    fontWeight: 800,
    fontSize: "70px",
    letterSpacing: "0.03em",

    color: "#FFFFFF",

    marginBottom: 16,
    textTransform: "uppercase",
    textAlign: "center",

    [theme.breakpoints.down("550")]: {
      fontSize: "55px",
    },
    [theme.breakpoints.down("500")]: {
      fontSize: "50px",
    },

    [theme.breakpoints.down("415")]: {
      fontSize: "40px",
    },

    [theme.breakpoints.down("350")]: {
      fontSize: "30px",
    },
  },

  subTitle: {
    fontWeight: 500,
    fontSize: "24px",
    letterSpacing: "0.03em",

    color: "#FFFFFF",

    marginBottom: 40,
    textAlign: "center",

    [theme.breakpoints.down("550")]: {
      fontSize: "18px",
    },

    [theme.breakpoints.down("350")]: {
      fontSize: "14px",
    },
  },

  button: {
    height: 60,
    width: 340,
    borderRadius: 10,
    background: "#fff",
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    "&:hover": {
      textDecoration: "none",
    },
  },

  buttonText: {
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "10px",

    color: "#090613",
  },

  cardsContainer: {
    marginBottom: 16,

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
    },
  },

  exchangeCardTablet: {
    display: "none",

    [theme.breakpoints.down("1100")]: {
      display: "flex",
    },
  },
  exchangeCardNonTablet: {
    [theme.breakpoints.down("1100")]: {
      display: "none",
    },
  },
  walletHolderFeedContainer: {
    display: "none",
    [theme.breakpoints.down("650")]: {
      display: "flex",
    },
  },

  walletHolderFeedContainerTablet: {
    display: "none",
    [theme.breakpoints.up("650")]: {
      display: "flex",
    },
  },
}));
