// Polygon Mumbai testnet
// export const DAFI_ADDRESS_POLYGON_MUMBAI =
//   "0xe61B223100676D6Ffa588C7c6720348DCAD3131F";

// export const NETWORK_DEMAND_POLYGON_MUMBAI =
//   "0xE14378f40379AB435c25f5DF5341F00eAB6065d1";

// export const STAKING_DATABASE_POLYGON_MUMBAI =
//   "0xeae3C9995fCcD6acad5c471241349C4913b82CBC";

// export const STAKING_MANAGER_POLYGON_MUMBAI =
//   "0xa37297F3e12BDbDF7d6377DDF916cdCaf3fD960e";

export const DAFI_ADDRESS_POLYGON_MUMBAI =
  "0xe61B223100676D6Ffa588C7c6720348DCAD3131F";

export const NETWORK_DEMAND_POLYGON_MUMBAI =
  "0xF487571686c6Dc5c905409572D28f58DEC8aB4DB";

export const STAKING_DATABASE_POLYGON_MUMBAI =
  "0xd2A871713509843c0dcbA26eF7885986EDD767df";

export const STAKING_MANAGER_POLYGON_MUMBAI =
  "0x49122e3E5C30577799aaA480D097Cb2940a8faF3";

// End

// App Mainnet
export const TOKEN_ADDRESS_POLYGON_MAINNET =
  "0xfd195a17e2a60d248a7148a919c5166ae907479a";

export const NETWORK_DEMAND_POLYGON_MAINNET =
  "0x37C23c7A7Aa98640f36f6a1F7DE7F0861be4654a";

export const STAKING_DATABASE_POLYGON_MAINNET =
  "0xbE9547D111867B842fe3435e365d4FC89057d927";

export const STAKING_MANAGER_POLYGON_MAINNET =
  "0x0534f881fd08FE8893eF76403Ea5B0aaDd05B56B";
//

// App Mainnet 2
export const TOKEN_ADDRESS_ETHEREUM_MAINNET = "";
// "0x0335A7610D817aeCA1bEBbEfbd392ecC2eD587B8";

export const NETWORK_DEMAND_ETHEREUM_MAINNET = "";
  // "0x8028CBdf9B31C10ba7EAEf9478f9a803B9369202";

export const STAKING_DATABASE_ETHEREUM_MAINNET = "";
  // "0xDd0fE322E6f402642Afd951064c3a592b6c31993";

export const STAKING_MANAGER_ETHEREUM_MAINNET = "";
  // "0x6064ceAaa4138Aa7d872b96e707dA4a4c3fD1619";
//

// export const API_BASE_URL = "http://172.18.1.29:5000";

export const API_BASE_URL = "https://backend-kolnet.superstaking.io";
export const API_DEV_BASE_URL = "54.157.99.22:3000";
export const GRAPH_BASE_URL = 
  "https://api.thegraph.com/subgraphs/name/mauwia/";
