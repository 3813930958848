import { Grid } from "@material-ui/core";
import React, { useEffect } from "react";

import TopUsersCard from "../components/DashbordComponents/TopUsersCard";

import Cards from "./Cards";

import { APP_DASHBOARD_TITLE } from "../utils/constants";
import GraphCard from "../components/DashbordComponents/GraphCard";
import TransactionCard from "../components/DashbordComponents/TransactionCard";

const Dashboard = () => {
  useEffect(() => {
    document.querySelector("title").textContent = APP_DASHBOARD_TITLE;
  }, []);

  return (
    <>
      <Grid spacing={3}>
        <Grid container justifyContent="space-between">
          <Cards />
        </Grid>

        <Grid container>
          <Grid item xs={12} md={7} lg={8}>
            <GraphCard />
          </Grid>
          <Grid item xs={12} md={5} lg={4}>
            <TopUsersCard />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12} md={12} lg={12}>
            <TransactionCard />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Dashboard;
