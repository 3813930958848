import * as web3Types from "../types/web3Types";

const initialState = {
  web3context: null,
  data: {
    totalDafiStaked: 0,
    feesCollected: 0,
    dafiStaked: 0,
    rewards: 0,
    potential: 0,
    topFiveUsers: [],
    transactionsHistory: [],
    approved: false,
    graphData: null,
    superPoolGraphData: null,
    superPoolGraphDataEth: null,
    minStakingAmount: null,
    minStakingPeriod: null,
    stakingPeriod: null,
    appLoading: false,
    firstAppLoading: true,
    stakingPeriodValue: null,
    OverallTotalStakes: null,
    overallAPY: null,
    overallPotentialAPY: null,
    walletHolders: {},
    marketGraph: null,
    coinCurrentPrice: null,
    coin24hVolume: null,
    sevenDayHighLow: [],
    demandFactor: null,
  },

  networkSet: false,
  currentNetwork: null,
  gasPrice: null,
  isTrustWallet: false,
  leaderboardData: null,
  tokenBalance: 0,
};

const web3Reducer = (state = initialState, action) => {
  const { type, payload } = action;

  console.log("typ => ", type, { ...state.data });
  switch (type) {
    case web3Types.STORE_WEB3_CONTEXT:
      return { ...state, web3context: { ...payload } };

    case web3Types.SET_CHAIN_DATA:
      return { ...state, data: { ...state.data, ...payload } };

    case web3Types.SET_NETWORK: {
      return {
        ...state,
        networkSet: payload,
      };
    }

    case web3Types.SET_CURRENT_NETWORK: {
      return {
        ...state,
        currentNetwork: payload,
      };
    }

    case web3Types.SET_GAS_PRICE: {
      return {
        ...state,
        gasPrice: payload,
      };
    }

    case web3Types.RESET_DATA: {
      return {
        ...state,
        data: initialState.data,
      };
    }

    case web3Types.ACTIVE_TRUST_WALLET: {
      return {
        ...state,
        isTrustWallet: payload,
      };
    }

    case web3Types.SET_LEADERBOARD_DATA: {
      return {
        ...state,
        leaderboardData: payload,
      };
    }

    case web3Types.SET_TOKEN_BALANCE: {
      return {
        ...state,
        tokenBalance: payload,
      };
    }

    default:
      return state;
  }
};

export default web3Reducer;
