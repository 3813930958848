import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InfoImg from "../components/withdraw_components/InfoImg.js";

import CustomModal from "../components/withdraw_components/CustomModal.js";

import { Box } from "@material-ui/core";

import { useWeb3React } from "@web3-react/core";
import { withdrawAmount } from "../contract/functions/functions";

import { setLoading, showSnackbar, getChainData } from "../redux";

import { Card, CardContent, makeStyles, Typography } from "@material-ui/core";

import {
  AMOUNT_FEE_RATIO,
  APP_TOKEN_NAME,
  APP_UNSTAKING_MESSAGE,
  APP_WITHDRAW_MESSAGE
} from "../utils/constants.js";
import { InfoPng } from "../assets/index.js";
import AmountInput from "../components/stake_components/AmountInput.js";
import BlockBtn from "../components/stake_components/BlockBtn.js";

const Withdraw = () => {
  const classes = useStyles();
  const context = useWeb3React();
  const dispatch = useDispatch();

  const { loading } = useSelector((state) => state.ui);

  const networkSet = useSelector((state) => state.web3.networkSet);
  const minStakingAmount = useSelector(
    (state) => state.web3.data.minStakingAmount
  );
  const minStakingPeriod = useSelector(
    (state) => state.web3.data.minStakingPeriod
  );

  const stakingPeriodValue = useSelector(
    (state) => state.web3.data.stakingPeriodValue
  );

  const stakingPeriod = useSelector((state) => state.web3.data.stakingPeriod);

  const [open, setOpen] = useState(false);
  const [modalText, setModalText] = useState("");
  const [modalLoadText, setModalLoadText] = useState("");

  const [actualReward, setActualReward] = useState(0);

  const [userInput, setUserInput] = useState();

  const [inputErrText, setInputErrText] = useState("");
  const [showInputErr, setShowInputErr] = useState(false);

  const currentNetwork = useSelector((state) => state.web3.currentNetwork);
  const { approved } = useSelector((state) => state.web3.data);
  const userData = useSelector((state) => state.web3.data);

  const getChainDataFunc = () => {
    dispatch(getChainData(context.account, currentNetwork, context.chainId));
  };
  useEffect(() => {
    if (context.account && context.active && networkSet) {
      getChainDataFunc();
    }
  }, [context, networkSet]);

  const changeLoadingStatus = (flag) => {
    dispatch(setLoading(flag));
  };

  const onWithdraw = async () => {
    let partialClaimBool = false;
    let cutReward = (userData.rewards * AMOUNT_FEE_RATIO).toFixed(4);

    if (Number(userInput) && Number(userInput) < cutReward) {
      partialClaimBool = true;
    }

    console.log("OnWithdraw values : ", {
      partialClaimBool,
      actualReward,
      rewards: (userData.rewards * AMOUNT_FEE_RATIO).toFixed(4),
      userInput: userInput / userData.demandFactorV2,
      cutReward,
      demandFactorV2: userData.demandFactorV2,
    });

    let withdrawResponse = await withdrawAmount(
      context.account,

      changeLoadingStatus,
      setOpen,
      showSnackbarFunc,
      partialClaimBool,
      partialClaimBool ? userInput / userData.demandFactorV2 : 0,
      setUserInput,
      () =>
        dispatch(getChainData(context.account, currentNetwork, context.chainId))
    );
    console.log("withdrawResponse => ", withdrawResponse);
  };

  const handleClickOpen = () => {
    setOpen(true);
    setModalText(
      `This action will withdraw your calculated d${APP_TOKEN_NAME.toUpperCase()} rewards until now`
    );
    setModalLoadText(`Withdrawing rewards`);
  };
  const handleClose = (value) => {
    setOpen(false);
  };

  let modalFunc = () => {
    onWithdraw();

    getChainDataFunc();
  };
  const showSnackbarFunc = (data, severity) => {
    dispatch(showSnackbar({ message: data, severity }));
  };

  useEffect(() => {
    if (context?.account) {
      setUserInput("");
    }
  }, [context?.account, context?.chainId]);

  const onChangeInput = (input) => {

    if (input.length>18) {
      setUserInput(userInput);
      return;
    }

    if (input.includes(" ")) {
      setUserInput("");
      return;
    }

    if (input.includes("-")) {
      setUserInput(userInput);
      return;
    }
    setInputErrText("");
    setShowInputErr(false);
    if (Number.isNaN(Number(input))) {
      setUserInput("");
    }
    if (Number(input) <= userData.rewards && Number(input) !== 0) {
      setInputErrText("");
      setShowInputErr(false);
      setUserInput(input);
      setActualReward(input);
    } else {
      if (Number(input)) {
        setUserInput(input);
        setActualReward(input);
        setInputErrText(
          "The amount entered is greater than your total value of rewards"
        );
      } else if (Number(input) === 0) {
        setUserInput(input);
        setActualReward(input);
        setInputErrText("Withdraw amount must be greater than zero");
      }
      setShowInputErr(true);
    }
  };

  const onMaxClick = () => {
    setShowInputErr(false);
    setInputErrText("");

    setUserInput(Number(userData.rewards * AMOUNT_FEE_RATIO)?.toFixed(4));
    setActualReward(Number(userData.rewards));
  };
  return (
    <div className={classes.container}>
      <CustomModal
        open={open}
        onClose={handleClose}
        text={modalText}
        modalFunc={modalFunc}
        loadText={modalLoadText}
        approved={approved}
        stakingPeriod={stakingPeriod}
        stakingPeriodValue={stakingPeriodValue}
        minStakingPeriod={minStakingPeriod}
      />
      <Box>
        <div className={classes.stakeContainer}>
          <Card elevation={1} className={classes.card}>
            <CardContent className={classes.cardContent}>
              <Typography className={classes.title}>Withdraw</Typography>
              <div className={classes.horizontalLine}></div>
{/* 
              {(currentNetwork === 2 ||
                currentNetwork === 3 ||
                currentNetwork === 4) && ( */}
                <div className={classes.withdrawInfo}>
                  <img src={InfoPng} className={classes.infoIcon} />
                  <Typography className={classes.InfoText}>
                    {APP_WITHDRAW_MESSAGE}
                  </Typography>
                </div>
              {/* )} */}
              <InfoImg
                selected={"Withdraw"}
                stakedAmount={userData.dafiStaked}
                rewards={userData.rewards}
              />

              <AmountInput
                selected={"Withdraw"}
                minStakingAmount={minStakingAmount}
                minStakingPeriod={minStakingPeriod}
                userInput={userInput}
                setUserInput={setUserInput}
                stakedAmount={userData.dafiStaked}
                inputErrText={inputErrText}
                showInputErr={showInputErr}
                setInputErrText={setInputErrText}
                setShowInputErr={setShowInputErr}
                rewards={userData.rewards}
                setActualReward={setActualReward}
                disabled={currentNetwork === 1 || context?.chainId !== 137}
                currentNetwork={currentNetwork}
                onMaxClick={onMaxClick}
                onChangeInput={onChangeInput}
                maxDisabled={userData.rewards <= 0}
              />

              <div className={classes.marginBtm}></div>
              <BlockBtn
                label={!loading ? "Withdraw" : "Transaction Pending"}
                handleClick={handleClickOpen}
                disabled={
                  showInputErr || loading || !userInput || !context.active
                }
              />
            </CardContent>
          </Card>
        </div>
      </Box>
    </div>
  );
};

export default Withdraw;

const useStyles = makeStyles((theme) => ({
  card: {
    background: theme.palette.cardBgColor,
    color: theme.palette.border,

    borderRadius: 25,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    boxShadow: "none",
    width: "473px",
    position: "relative",
    "@media(max-width:550px)": {
      width: "90%",
    },
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflowY: "scroll",
    border: 0,
  },
  container: {
    height: "100%",
    "@media(max-width:550px)": {
      marginLeft: "-16px",
    },
    "@media(max-width:320px)": {
      marginLeft: "0px",
    },
  },
  horizontalLine: {
    borderBottom: "1px solid #ffffff1A",
    width: "120%",
    position: "absolute",
    top: "54px",
  },
  infoIcon: {
    width: "20px",
    marginRight: "10px",
    "@media(max-width:550px)": {
      marginBottom: "6px",
    },
  },

  marginBtm: {
    marginBottom: "13px",
  },

  title: {
    fontWeight: 600,
    fontSize: "18px",
    color: theme.palette.textColor,
    marginBottom: "38px",
  },

  stakeContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  InfoText: {
    fontSize: "14px",
    lineHeight: "19px",
    color: theme.palette.textColor,
  },
  withdrawInfo: {
    backgroundColor: theme.palette.cardBgColor,
    border: theme.palette.border,
    color: "#FFF",
    padding: "14px",
    width: "100%",
    borderRadius: "10px",
    // textAlign: "center",
    marginBottom: "20px",
    marginTop: "-10px",
    fontWeight: 300,
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
    "@media(max-width:550px)": {
      display: "flex",
      flexDirection: "column",
      textAlign: "center",
    },
  },
}));
