import { Box, Button, Link, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { MarketComingSoon } from "../assets";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100%",
    paddingRight: 16,
  },
  card: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },

  logo: {
    marginBottom: 30,

    [theme.breakpoints.down("415")]: {
      height: 300,
    },
  },

  title: {
    fontWeight: 800,
    fontSize: "70px",
    letterSpacing: "0.03em",

    color: "#FFFFFF",

    marginBottom: 16,
    textTransform: "uppercase",
    textAlign: "center",

    [theme.breakpoints.down("550")]: {
      fontSize: "55px",
    },
    [theme.breakpoints.down("500")]: {
      fontSize: "50px",
    },

    [theme.breakpoints.down("415")]: {
      fontSize: "40px",
    },

    [theme.breakpoints.down("350")]: {
      fontSize: "30px",
    },
  },

  subTitle: {
    fontWeight: 500,
    fontSize: "24px",
    letterSpacing: "0.03em",

    color: "#FFFFFF",

    marginBottom: 40,
    textAlign: "center",

    [theme.breakpoints.down("550")]: {
      fontSize: "18px",
    },

    [theme.breakpoints.down("350")]: {
      fontSize: "14px",
    },
  },

  button: {
    height: 60,
    width: 340,
    borderRadius: 10,
    background: "#fff",
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    "&:hover": {
      textDecoration: "none",
    },
  },

  buttonText: {
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "10px",

    color: "#090613",
  },
}));
const MarketX = () => {
  const classes = useStyles();

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      className={classes.container}
    >
      <Box className={classes.card}>
        <Typography className={classes.title}>Coming Soon</Typography>

        <Typography className={classes.subTitle}>
          Hang on, something else is landing your way
        </Typography>

        <img src={MarketComingSoon} className={classes.logo} />
      </Box>
    </Box>
  );
};

export default MarketX;
