import { useSelector } from "react-redux";

import { showSnackbar, hideSnackbar, showSnackbarInDeep } from "../redux";
import { useDispatchWrap } from "./utilHooks";

export const useSnackbar = () => {
  const snackbar = useSelector((state) => state.ui.snackbar);
  const showSnackbarF = useDispatchWrap(showSnackbar);
  const hideSnackbarF = useDispatchWrap(hideSnackbar);
  const showSnackbarInDeepF = useDispatchWrap(showSnackbarInDeep);

  return { ...snackbar, showSnackbarF, hideSnackbarF, showSnackbarInDeepF };
};
