import { Box, makeStyles, Typography, Button } from "@material-ui/core";
import { useWeb3React } from "@web3-react/core";
import React, { useEffect, useState } from "react";
import {
  AddDafiSvg,
  BinanceIcon,
  EthereumIcon,
  InfoIcon,
  MaticIcon,
  PolygenLogo,
  PolygonPng,
  WarningIcon,
} from "../assets";
import { AddIcon } from "@material-ui/icons/Add";
import { MoreHoriz } from "@material-ui/icons";
import { Modal } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import ConnectWalletModal from "./Modals/ConnectWalletModal";
import HelpModal from "./Modals/HelpModal";
import { conciseAddress, sixDigitsFormatter } from "../utils/formatters";
import {
  getBalance,
  getDafiCount,
  registerToken,
  sendTransaction,
} from "../contract/functions/functions";
import { useDispatch, useSelector } from "react-redux";
import { getTokenBalance, setCurrentNetwork } from "../redux";
import HelpDropdown from "./Misc/HelpDropdown";
import MessageModal from "./Modals/MessageModal";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import Text from "./Misc/Text";
import { APP_TOKEN_NAME } from "../utils/constants";

const Header = () => {
  const web3context = useWeb3React();

  const classes = useStyles();

  const [modal, setModal] = useState(false);
  const [helpModal, setHelpModal] = useState(false);

  // const [currentNetwork, setCurrentNetwork] = useState(null);
  const [dafiBalance, setDafiBalance] = useState(0);

  let currentNetwork = useSelector((state) => state.web3.currentNetwork);

  let tokenBalance = useSelector((state) => state.web3.tokenBalance);

  const networkSet = useSelector((state) => state.web3.networkSet);

  const dispatch = useDispatch();

  const [messageModal, setMessageModal] = useState(false);

  const isTrustWallet = useSelector((state) => state.web3.isTrustWallet);

  const [helpModalText, setHelpModalText] = useState("");

  const location = useLocation();

  useEffect(() => {
    if (web3context.active && web3context?.account) {
      console.log(
        "defaulting network => ",
        web3context.chainId,
        currentNetwork
      );

      dispatch({
        type: "SET_NETWORK",
        payload: false,
      });

      if (web3context.chainId === 1 && currentNetwork === 2) {
        // dispatch(setCurrentNetwork(1));
      } else if (web3context.chainId === 1 && !currentNetwork) {
        dispatch(setCurrentNetwork(2));
      } else if (web3context.chainId === 80001) {
        dispatch(setCurrentNetwork(4));
      } else if (web3context.chainId === 137) {
        dispatch(setCurrentNetwork(3));
      }
    }
  }, [web3context]);

  useEffect(() => {
    if (web3context.account && networkSet) {
      dispatch(getTokenBalance(web3context.account));
    }
  }, [web3context, networkSet]);

  const onChainChanged = (network) => {
    console.log("Setting network => ", network);

    dispatch({
      type: "RESET_DATA",
    });
    dispatch(setCurrentNetwork(network));
    // dispatch({
    //   type: "SET_NETWORK",
    //   payload: true,
    // });
  };

  const onNetworkChange = async (chainId, network) => {
    console.log("onNetworkChange => ", {
      currentNetwork,
      network,
      isTrustWallet,
      networkSet,
    });

    dispatch({
      type: "SET_NETWORK",
      payload: false,
    });

    if (!isTrustWallet) {
      if (currentNetwork === 1 && network === 2) {
        dispatch({
          type: "RESET_DATA",
        });
        dispatch(setCurrentNetwork(network));
      } else {
        sendTransaction(chainId, () => onChainChanged(network));
      }
    } else {
      dispatch({
        type: "RESET_DATA",
      });
      dispatch({
        type: "SET_NETWORK",
        payload: true,
      });
      if (currentNetwork === 1 && network === 2) {
        dispatch(setCurrentNetwork(network));
      } else if (currentNetwork === 2 && network === 1) {
        dispatch(setCurrentNetwork(network));
      } else {
        dispatch(setCurrentNetwork(network));
      }
    }
  };
  console.log("currentNetwork",currentNetwork)
  return (
    <>
      <Box className={classes.connectBtnContainer}>
        <Box></Box>
        {!web3context?.active && !web3context?.account && (
          <Box
            display="flex"
            alignItems="center"
            className={classes.infoContainer}
          >
            <img src={WarningIcon} />

            <Text
              text="Please connect your wallet to view your information"
              fontSize={14}
              lineHeight={20}
              textAlign="center"
              customStyles={classes.info}
            />
          </Box>
        )}

        <Box className={classes.connectWalletContainer}>
          {web3context?.active && web3context?.account && (
<>
            <Button
              className="addDafiBox-tooltip"
              onClick={() => registerToken()}
            >
              <img src={PolygenLogo} style={{ height: 30 }} />

              <Box className="addDafiBox-tooltipText">
                <Typography className={classes.dafiAddToolTipText}>
                  Add {APP_TOKEN_NAME} to your MetaMask wallet
                </Typography>
              </Box>
            </Button>

            </>
          )}

          {web3context?.active && web3context?.account && (
 <Button
              className={classes.networkChangeBtn}
              onClick={() => {
                if (currentNetwork !== 3) onNetworkChange("0x89", 3);
              }}
            >
              {currentNetwork === 3 || currentNetwork === 4 ? (
                <>
                  <img src={MaticIcon} className={classes.networkImage} />

                  <Text
                    text="Polygon"
                    fontSize={16}
                    lineHeight={14}
                    fontWeight={400}
                  />
                </>
              ) : (
                <>
                  <Text
                    text="Switch to Polygon"
                    fontSize={16}
                    lineHeight={14}
                    fontWeight={400}
                  />
                </>
              )}
            </Button>
          )}

          <Box
            className={[
              classes.connectBtn,
              web3context?.account && classes.connectedBtn,
            ]}
          >
            {web3context?.active && (
              <Box className={classes.dafiCountBox}>
                <Typography className={classes.dafiCountText}>
                  {web3context?.chainId===137 ? sixDigitsFormatter(tokenBalance) : 0}{" "}
                  {APP_TOKEN_NAME.toUpperCase()}
                </Typography>
              </Box>
            )}
            <Button
              className={[
                web3context?.account
                  ? classes.addressBox
                  : classes.connectBtnOnly,
              ]}
              onClick={() => setModal(true)}
              // startIcon={
              //   !web3context?.active && !web3context?.account && <AddIcon />
              // }
              variant={
                web3context?.active && web3context?.account
                  ? "outlined"
                  : "contained"
              }
            >
              <Typography
                className={[
                  web3context?.account
                  ? classes.connectedText
                  : classes.disconnectedText,
                ]}
                >
                {web3context?.active && web3context?.account
                  ? conciseAddress(web3context?.account)
                  : "CONNECT WALLET"}
              </Typography>
                  {web3context?.active && web3context?.account && (
                    <div className={[classes.activeDot]}></div>
                  )}
            </Button>
          </Box>

          <HelpDropdown />
        </Box>
      </Box>
      {/* {web3context.chainId === 1 &&
        (location.pathname === "/" || location.pathname === "/stake") && (
          <Button
            className={classes.mobileNetworkSwitchBtn}
            onClick={() => {
              dispatch({
                type: "RESET_DATA",
              });
              if (currentNetwork === 1) {
                dispatch(setCurrentNetwork(2));
              } else {
                dispatch(setCurrentNetwork(1));
              }
            }}
          >
            <Typography style={{ color: "#fff" }}>
              {currentNetwork === 1
                ? "Switch to Ethereum V2"
                : "Switch to Ethereum V1"}
            </Typography>
          </Button>
        )} */}

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={modal}
        onClose={() => setModal(false)}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modal}>
          <ConnectWalletModal onSuccess={() => setModal(false)} />
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={helpModal}
        onClose={() => setHelpModal(false)}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 100,
        }}
      >
        <Fade in={helpModal}>
          <MessageModal
            message={helpModalText}
            onSuccess={() => setHelpModal(false)}
          />
        </Fade>
      </Modal>
    </>
  );
};

export default Header;

const useStyles = makeStyles((theme) => ({
  currentNetwork : {
    width: 147,
    height: 49,
    borderRadius: 10,
    marginRight: 15,
    background: "#fff",
  },
  connectBtn: {
    width: 240,
    height: 50,
    marginRight: 10,
  },

  connectBtnContainer: {
    marginBottom: 30,
    display: "flex",
    // alignItems: "center",

    justifyContent: "space-between",
    marginRight: 16,

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
      marginRight: 0,
    },

    [theme.breakpoints.down("xs")]: {
      alignItems: "center",
    },

    [theme.breakpoints.down("500")]: {
      alignItems: "stretch",
    },
  },

  connectWalletContainer: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      marginBottom: 20,
      justifyContent: "flex-end",
      display: "flex",
    },
  },

  modal: {
    display: "flex",
    // marginTop: "10%",
    alignItems: "center",
    justifyContent: "center",
  },

  info: {
    textAlign: "center",
    color: theme.palette.textColor,

    [theme.breakpoints.up("xs")]: {
      marginLeft: 10,
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: 10,
    },
  },

  infoContainer: {
    background: theme.palette.themeColor1,
    border: theme.palette.border,
    borderWidth: 1,
    borderRadius: 10,
    minHeight: 40,
    padding: "0 30px",

    [theme.breakpoints.down("sm")]: {
      width: "80%",
      justifyContent: "center",
      alignSelf: "center",
    },

    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      width: "90%",
      minHeight: 100,
      justifyContent: "center",
    },
  },

  connectedBtn: {
    background: theme.palette.themeColor1,

    border: "1px solid rgba(255, 255, 255, 0.28)",
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    width: 380,

    [theme.breakpoints.down("500")]: {
      width: "90%",
    },

    [theme.breakpoints.down("400")]: {
      // width: 170,
      width: "90%",
      marginRight: 8,
    },

    "&:hover": {
      background: theme.palette.themeColor1,
    },
  },

  connectedText: {
    color: theme.palette.textColor,
    fontSize: 16,
    lineHeight: "14px",
    fontWeight: 400,
  },

  disconnectedText: {
    color: theme.palette.textColor,
    fontSize: 16,
    lineHeight: "14px",
    fontWeight: 400,
  },
  activeDot: {
    background: "linear-gradient(180deg, #00E2FE 0%, #19FADD 100%)",
    height: 20,
    width: 20,
    borderRadius: 100,
    marginLeft: 10,

    [theme.breakpoints.down("600")]: {
      // width: 170,
      height: 10,
      width: 10,
    },
  },

  networkBtn: {
    height: 50,
    background: "rgba(15, 8, 36, 0.55)",
    border: "1px solid rgba(255, 255, 255, 0.28)",
    borderRadius: "10px",
    marginRight: 15,
    width: "fit-content",
    [theme.breakpoints.down("500")]: {
      width: 90,
    },

    [theme.breakpoints.down("400")]: {
      width: 80,
      marginRight: 8,
    },
  },

  networkImage: {
    height: 20,
    width: 20,
    marginRight: 8,
  },

  InfoButton: {
    padding: 0,
    background: "#190E2B",

    border: "1px solid rgba(255, 255, 255, 0.58)",
    borderRadius: "10px",
    [theme.breakpoints.down("500")]: {},
  },

  infoIcon: {
    color: "#fff",
  },

  dafiAddToolTipText: {
    fontSize: 14,
    lineHeight: "17px",
    fontWeight: 600,
    color: "#fff",
  },

  networkTextBox: {
    background: "#190E2B",
    borderRadius: "10px",
    display: "flex",
    height: 40,
    padding: "0 10px",
    justifyContent: "center",
    alignItems: "center",
    marginRight: 12,
  },

  networkTextBoxSelected: {
    border: "1px solid rgba(130, 158, 255, 1)",
  },

  addressBox: {
    background: theme.palette.themeColor1,
    display: "flex",
    alignItems: "center",
    // border: "1px solid rgba(255, 255, 255, 0.28)",
    border: 0,
    borderLeft: theme.palette.border,

    height: "100%",

    borderRadius: 10,
    flex: 4,
  },

  connectBtnOnly: {
    display: "flex",
    alignItems: "center",
    background: theme.palette.themeColor1,

    height: "100%",

    borderRadius: 10,
    flex: 4,
    marginBottom: 20,

    "&:hover": {
      background: theme.palette.themeColor1,
    },
  },
  dafiCountBox: {
    padding: "10px",
    flex: 2,
    // background: theme.palette.cardBgColor,

    [theme.breakpoints.down("400")]: {
      padding: "10px",
    },
  },
  dafiCountText: {
    fontSize: 16,
    lineHeight: "14px",
    fontWeight: 400,
    color: theme.palette.textColor,
  },

  mobileNetworkSwitchBtn: {
    background: "rgba(15, 8, 36, 0.55)",
    display: "flex",
    alignItems: "center",
    border: "1px solid rgba(255, 255, 255, 0.28)",
    height: "50px",

    borderRadius: 10,
    margin: "0 auto",
    marginBottom: 20,

    [theme.breakpoints.up("600")]: {
      display: "none",
    },
  },

  networkChangeBtn: {
    background: theme.palette.themeColor1,
    border: theme.palette.border,
    borderWidth: 1,
    borderRadius: 10,
    marginRight: 15,

    [theme.breakpoints.down("600")]: {
      display: "none",
    },

    "&:hover": {
      background: theme.palette.themeColor1,
    },
  },
}));
